import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import parse from 'html-react-parser';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

import { GlobalDispatchContext } from '../../context/GlobalContextProvider';
import { ThemeDispatchContext } from '../../context/ThemeContextProvider';

import './textPage.scss';

const wpPage = ({
  data: {
    wpPage: {
      title, content, acfImage, seo, language, translations,
    },
  },
}) => {
  useEffect(() => {
    dispatch({ type: 'SET_TRANSLATION_LINKS', payload: { translationLinks: translations } });
    dispatch({ type: 'SET_LANG', payload: { lang: language } });
    dispatchTheme({ type: 'SET_THEME', payload: { themeName: 'biodiversity' } });
  }, []);

  const dispatch = useContext(GlobalDispatchContext);
  const dispatchTheme = useContext(ThemeDispatchContext);
  const logosSrc = acfImage && acfImage.image ? acfImage.image.localFile
    .childImageSharp.gatsbyImageData.images.fallback.src : '';

  return (
    <Layout>
      <SEO
        title={title}
        meta={
        [
          { opengraphSiteName: seo.opengraphSiteName },
          { opengraphType: seo.opengraphType },
          { opengraphTitle: seo.opengraphTitle },
          { opengraphImage: seo.opengraphImage?.link },
          { imageHeight: seo.opengraphImage?.mediaDetails.height },
          { imageWidth: seo.opengraphImage?.mediaDetails.width },
        ]
    }
      />
      <Container fluid className="text-page-container">
        <Row className="justify-content-center align-items-center">
          <Col xs={8}>
            <h1 className="text-bold">{title}</h1>
            {parse(content)}
            {logosSrc
              ? (
                <img
                  className="logos-img"
                  src={logosSrc}
                  alt="logos"
                />
              )
              : ''}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export default wpPage;

export const query = graphql`
  query($id: String) {
    wpPage(id: { eq: $id }) {
      id  
      title
      content
      acfImage {
        image {
          localFile {
             childImageSharp {
                gatsbyImageData(width: 1000)
            }
          }
        }
      }
      translations {
        language {
          locale
          name
        }
        uri
      }
      language {
          locale
          name
      }
      seo {
          opengraphUrl
          opengraphSiteName
          opengraphType
          opengraphTitle
          opengraphImage {
            link
            mediaDetails {
              height
              width
            }
          }
         }
    }
  }
`;
